<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("client.accounting") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("client.accountingTax") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.accountingHasVat") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="client.additional_data.accounting.hasVatNumber"
                  type="checkbox"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div v-show="hasVat" class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.accountingVatNumber") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.additional_data.accounting.vatNumber"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.accountingNoVat") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="client.additional_data.accounting.noVat"
                  type="checkbox"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">
              {{ $t("client.accountingInvoicing") }}:
            </h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.accountingInvoicesSendEmail") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="client.additional_data.accounting.invoicesSendEmail"
                  type="checkbox"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div v-show="sendEmail" class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("client.accountingInvoicesEmail") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="client.additional_data.accounting.invoicesEmail"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Client from "@/components/Admins/Clients/clients";
import Tenant from "@/components/Tenants/tenant";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      client: {
        additional_data: {
          accounting: {}
        }
      },
      isBusy: false
    };
  },
  computed: {
    hasVat: function () {
      return !!this.client.additional_data.accounting.hasVatNumber;
    },
    sendEmail: function () {
      return !!this.client.additional_data.accounting.invoicesSendEmail;
    }
  },
  mounted() {
    this.getClient();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.tenantAccounting"),
        route: ""
      },
      {
        title: this.$t("menu.tenantAccountingInformation"),
        route: "/tenant/accounting/information"
      }
    ]);
  },
  methods: {
    async getClient() {
      this.isBusy = true;
      let data = {};
      if (this.$store.getters.userType === "partner") {
        data = await Tenant.getSelectedTenant();
      } else {
        data = await Tenant.getTenant();
      }
      this.validateProps(data);
      this.isBusy = false;
    },
    async saveData() {
      let clientId = this.client.id;
      const data = {
        name: this.client.name,
        additional_data: this.client.additional_data
      };
      Client.update(clientId, data)
        .then(response => {
          let client = response.data.data;
          this.validateProps(client);
          this.$toast.fire({
            icon: "success",
            title: this.$t("client.clientUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    validateProps(client) {
      if (!client.additional_data) client.additional_data = { accounting: {} };
      if (!client.additional_data.accounting)
        client.additional_data.accounting = {};
      this.client = client;
    }
  }
};
</script>
